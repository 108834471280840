<template>
    <div>
        <card-header title="Customize Event" icon="fa-cog"/>

        <card-body>
            <card-list>
                <event-list-item :card="card" :event="event"/>
            </card-list>
            <override-form :event="newEvent"/>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" :disabled="false" @click="submit"><i class="fas fa-plus mr-3"></i>Add Customization</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardBody from "../../TIER/components/CardBody";
    import CardFooter from "../../TIER/components/CardFooter";
    import OverrideForm from "./OverrideForm";
    import {client as http} from "../../http_client";
    import async from "async";
    import {mapActions} from "vuex";
    import EventListItem from "@/components/EventListItem";
    import CardList from "@/TIER/components/CardList";

    export default {
        components: {CardList, EventListItem, OverrideForm, CardFooter, CardBody, CardHeader},
        props: ['card', 'child', 'props'],
        data: function() {
            return {
                newEvent: {
                    active: 1,
                    description: ''
                },
                event: {}
            }
        },
        methods: {
            ...mapActions(['updateTasks']),
            loadEvent: function () {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId + '/days/' + this.props.dayId + '/events/' + this.props.eventId, {force: true}).then(response => {
                    this.event = response.data;
                    this.newEvent.description = response.data.description;
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);
                http.post('/api/registrations/' + this.props.registrationId + '/events', this.newEvent, {force: true}).then(response => {
                    this.updateTasks();
                    this.$reloadCard('registrations');
                    this.$reloadCard('registration', {registrationId: this.props.registrationId});
                    this.$reloadCard('itinerary', {registrationId: this.props.registrationId});
                    this.$reloadCard('events', {dayId: this.props.dayId});
                    this.$reloadCard('tasks');
                    this.$reloadCard('task-details', {type: 'reg-events'});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            }
        },
        watch: {
            'props': function() {
                this.newEvent.day_id = this.props.dayId;
                this.newEvent.registration_id = this.props.registrationId;
                this.newEvent.day_event_id = this.props.eventId;
                this.$saveAlert(true, 'Are you done customizing this event? Unsaved changes will be lost.');
                this.loadEvent();
            }
        },
        mounted() {
            this.newEvent.day_id = this.props.dayId;
            this.newEvent.registration_id = this.props.registrationId;
            this.newEvent.day_event_id = this.props.eventId;
            this.$saveAlert(true, 'Are you done customizing this event? Unsaved changes will be lost.');
            this.loadEvent();
        }
    };
</script>

<style scoped>

</style>
